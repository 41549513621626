  .fa-times-Education  {
    color: #ffffff;
    font-size: 2rem;
    background-color: #241f0f;
    /* padding: 5mm; */
  }
  .fa-title-Education  {
    color: #ffffff;
    font-size: 2rem;
    background-color: #241f0f;
    padding: 5mm;
    text-align: left;
  }

  .fa-medium-Education  {
    color: #ffffff;
    font-size: 2rem;
    background-color: #241f0f;
  }
  .fa-title2-Education  {
    color: #ffffff;
    font-size: 1.5rem;
    background-color: #241f0f;
    padding-left: 5mm;
    text-align: left;
  }
  .fa-small-Education {
    color: #ffffff;
    font-size-adjust: inherit;
    background-color:#241f0f;
    padding-left: 5mm;
    text-align: left;
  }

  a{
    text-decoration: none;
    color: inherit;
    text-decoration: underline;
  }
  .img-education {
    background-color: #241f0f;
    padding-top: 5mm;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    left: 50px;
  }
  .school-description  {
    color: #ffffff;
    font-size: 1rem;
    background-color: #241f0f;
  }