.fa-times-red  {
    color: #ffffff;
    font-size: 2rem;
    background-color: #c7c2bb;
    padding: 5mm;
  }

  .fa-medium-red  {
    color: #ffffff;
    font-size: 2rem;
    background-color: #c7c2bb;
  }

  .job-title  {
    color: #ffffff;
    font-size: 1rem;
    background-color: #c7c2bb;
  }

  .fa-small-red {
    color: #ffffff;
    font-size: 1rem;
    background-color: #c7c2bb;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2mm;
    text-align: center; 
  }


  .img-red {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    background-color: #c7c2bb;
  }



  .fa-times-blue  {
    color: #ffffff;
    font-size: 2rem;
    background-color:  #241f0f;
    padding: 5mm;
  }

  .fa-medium-blue  {
    color: #ffffff;
    font-size: 1.5rem;
    background-color:  #241f0f;
    padding-left: 5%;
    padding-right: 5%;
    text-align: left;  
    }

  .fa-small-blue {
    color: #ffffff;
    font-size: 1rem;
    background-color:  #241f0f;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5mm;
    text-align: left;  
  }


  .img-blue {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    background-color:  #241f0f;
  }
  .background-pink {
    background-color: #c7c2bb;
  }


  .img-experience-pink {
    background-color: #c7c2bb;
    padding-top: 5mm;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    left: 50px;
  }

  
