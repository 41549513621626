.fa-times {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    padding-top: 1mm;
    /* font-family:sans-serif; */
  }

  .fa-medium {
    color: rgb(255, 255, 255);
    font-size: 1rem;
    padding-top: 1mm;
    padding-left: 5mm;
    padding-right: 5mm;
    text-align: left;
    
  }

  .fa-small {
    color: rgb(255, 255, 255);
    font-size: 1rem;
    padding-top: 5mm;
    padding-bottom: 5mm;
    text-align: center;
  }

  .img-home {
    padding-top: 5mm;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    left: 50px;
  }
  .background-blue {
    background-color: #241f0f;
  }

  
